import logo from './logo.svg';
import './App.css';
import PricesTable from './prices.js';


import { useEffect, useState } from "react";

const App = () => {


  


  //console.log(prices.length)

  return (
    <div className="App">
      <PricesTable />
    </div>
  );
}

export default App;










