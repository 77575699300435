import prices from "./prices.json";
import './App.css';
import { useState } from "react";

const PricesTable = () => {

  /*const playerRow = (player) => {
    return(
       <div className="player">
          <div className="player=name">{player.playerName}</div>
          <div className="quote-buy">{player.buy}></div>
          <div className="quote-sell">{player.sell}</div>
       </div>
    );
  };*/

    console.log(prices.length)

    const results = () => {
      return (
        <div className="results-cont">
          <div className="results-title">Соберите свою команду из участников Итогового турнира ATP!</div>
          <div className="results-intro">Ваш итоговый результат - <b>КОЛИЧЕСТВО РЕЙТИНГОВЫХ ОЧКОВ</b>, заработанных вашей командой</div>
          <div className="results-intro">Кроме того вы сможете в любой момент продать ранее купленных игроков</div>
          <div className="results-intro"><b>Рейтинговые очки игрока в зависимости от результата:</b></div>
          <div className="results-table">
            <div className="results-row row-dark">
              <div className="left-border">Любая победа на груповом этапе</div>
              <div className="right-border">200 очков</div>
            </div>
            <div className="results-row row-light">
              <div className="left-border">Победа в полуфинале</div>
              <div className="right-border">400 очков</div>
            </div>
            <div className="results-row row-dark">
              <div className="left-border">Победа в финале</div>
              <div className="right-border">500 очков</div>
            </div>
            <div className="results-row row-light">
              <div className="left-border"><b>Выигрыш всех матчей</b></div>
              <div className="right-border"><b>1500 очков</b></div>
            </div>
          </div>
        </div>)
    };


    const [money, setMoney] = useState(2050);
    /*const [state, sesState] = useState({
      "players": [],
      "price": 0,
      "residual": {money}
    });*/

    const available = () => {
      console.log(money);
      return (
        <div className="available">
          <div>Денежные средства:</div>
          <div className="number-field">{money}</div>
        </div>)
    };
    
    const [team, setTeam] = useState(0);
    const teamCost = () => {
      return (
        <div className="available">
          <div>Стоимость команды:</div>
          <div className="number-field">{team}</div>
        </div>);
    };

    const selectedDefault = 'Пока никого не выбрано';
    //const selectedPlayers = [selectedDefault];
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedJoined, setSelectedJoined] = useState(<div>{selectedDefault}</div>);

    const makeArrayDivs = (array, newItem) => {
      if (array.length === 0){
        return (<div>{newItem}</div>)
      }else{
        const arrayString = array.join(', ') + ', ' + newItem
        return arrayString
      }
    };


    const selectedDiv = () => {
      console.log(selectedPlayers)
      console.log(selectedJoined)
      const playersDivs = () => {
        selectedPlayers.forEach((player) => {<div>player</div>})
      }
      return (
        <div className="available">
          <div className="available-left">Состав команды:</div>
          <div className="available-right">{selectedJoined}</div>
        </div>
      )
    };    
    
    const CreateSelected = (array) => {



    }    

    const CreatePlayer = (player) => {
      const [isSelected, setIsSelected] = useState(false);
      const selectPlayer = () => {
        if (isSelected === false) {
          if (player.buy <= money) {
          setIsSelected(true);
          setSelectedPlayers(selectedPlayers => [...selectedPlayers, player.playerName]);
          setSelectedJoined(selectedJoined => makeArrayDivs(selectedPlayers, player.playerName))
          //console.log(selectedJoined)
          setMoney(money - player.buy)
          setTeam(team + player.sell)} else {}
        }else{}
      };

      
      const unselectPlayer = () => {
        if (isSelected === true) {
          setIsSelected(false);
          setSelectedPlayers(selectedPlayers => [...selectedPlayers.filter((word) => word !== player.playerName)]);
          setMoney(money + player.sell)
          setTeam(team - player.sell)           
        }else{}
      };

      return (
        <div className="player">
          <div className="player-name">{player.playerName}</div>
          <div className="button-div"><button className="quote-buy" disabled={isSelected ? true:false} onClick={() => selectPlayer()}>{player.buy}</button></div>
          <div className="button-div"><button className="quote-sell" disabled={isSelected ? false:true} onClick={() => unselectPlayer()}>{player.sell}</button></div>    
        </div>
      )
    }     

    const players = prices.map(player => CreatePlayer(player))

    /*const players = prices.map(player => 
    //const status = [isSelected, setIsSelected] = useState(false); 
    <div className="player">
      <div className="player-name">{player.playerName}</div>
      <div className="button-div"><button className="quote-buy">{player.buy}</button></div>
      <div className="button-div"><button className="quote-sell">{player.sell}</button></div>    
    </div>)*/

    return (
      <div className="main-table">
        <div>{results()}</div>
        <div>{available()}</div>
        <div>{selectedDiv()}</div>
        <div>{teamCost()}</div>
        <div className="first-row">
          <div className="first-cell"></div>
          <div className="quote-title">Купить</div>
          <div className="quote-title">Продать</div>
        </div>
        <div>{players}</div>
      </div>
    );

}


export default PricesTable;


